
























































































































































import { Vue, Component } from 'vue-property-decorator';
import GlobalEvents from 'vue-global-events';
import Timer from '../classes/Timer';

@Component({ components: { GlobalEvents } })
export default class TimerView extends Vue {
  timer: Timer = new Timer(this.$store.getters['timer/settings']);
  timerOn = false;
  coffee = false;
  storedCountdown = 0;
  settingsDialog = false;
  settings = this.$store.getters['timer/settings'];

  get backgroundColor(): string {
    let color = 'blue';
    if (this.timer.countdown <= this.settings.errorSeconds) color = 'error';
    else if (this.timer.countdown <= this.settings.warningSeconds) color = 'warning';
    else if (this.coffee) color = 'brown';
    return color;
  }

  mounted() {
    this.$bus.$emit('title-change', 'Timer');
    //this.settings = this.$store.getters['timer/settings'];
    this.$analytics.logEvent('timer-view', { user_id: this.$store.getters['user/userId'] });
    this.timer.reset();
  }

  startTimer() {
    this.$bus.$emit('snackbar-notify', 'Timer started', 'success');
    this.timerOn = true;
    this.timer.start();
  }
  pauseTimer() {
    this.$bus.$emit('snackbar-notify', 'Timer paused', 'info');
    this.timerOn = false;
    this.timer.pause();
  }
  stopTimer() {
    this.$bus.$emit('snackbar-notify', 'Timer stopped', 'error');
    this.timerOn = false;
    this.timer.stop();
  }
  resetTimer() {
    this.$bus.$emit('snackbar-notify', 'Timer reset', 'warning');
    const countdown = this.coffee ? this.settings.coffeeCountdown : this.settings.countdown;
    this.timer.reset(countdown);
  }
  toggleTimer() {
    this.timerOn ? this.stopTimer() : this.startTimer();
  }

  coffeeBreak() {
    if (!this.coffee) {
      this.coffee = true;
      this.$bus.$emit('snackbar-notify', 'Coffee break started', 'warning');
      this.timerOn = false;
      //this.timerOn = true;
      this.storedCountdown = this.timer.countdown;
      this.timer.stop();
      //this.timer.reset();
      this.timer.countdown = this.timer.initialCountdown = this.settings.coffeeCountdown;
      //this.timer.start(this.settings.coffeeCountdown);
    } else {
      this.coffee = false;
      this.$bus.$emit('snackbar-notify', 'Coffee break finished', 'warning');
      this.timerOn = false;
      this.timer.stop();
      this.timer.initialCountdown = this.settings.countdown;
      this.timer.countdown = this.storedCountdown;
      //this.timer.reset();
    }
  }

  toggleSound() {
    if (!this.settings.sound) {
      this.$bus.$emit('snackbar-notify', 'Timer sounds enabled', 'warning');
      this.settings.sound = true;
    } else {
      this.$bus.$emit('snackbar-notify', 'Timer sounds disabled', 'warning');
      this.settings.sound = false;
    }
    this.$store.dispatch('timer/setSound', this.settings.sound);
    this.timer.settings.sound = this.settings.sound;
  }

  updateSettings() {
    this.$store.dispatch('timer/setSettings', this.settings);
    this.timer.settings = this.settings;
    const countdown = this.coffee ? this.settings.coffeeCountdown : this.settings.countdown;
    this.timer.reset(countdown);
  }

  changeCountdown() {
    this.$store.dispatch('timer/setCountdown', this.timer.countdown);
  }

  changeParticipants() {
    this.$store.dispatch('timer/setParticipants', this.settings.participants);
  }

  changeCoffeeCountdown() {
    this.$store.dispatch('timer/setCoffeeCountdown', this.settings.coffeeCountdown);
    if (this.coffee) {
      this.timer.countdown = this.settings.coffeeCountdown;
    }
  }
}
